import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppCustomPreloader } from '@app/app-routing-loader';

import { AuthGuard } from '@guards/auth.guard';
import { ExternalGuard } from '@guards/external.guard';

let initialTab = window.localStorage.getItem('initialTab') ?? 'last';
if (initialTab === 'today') initialTab = 'dashboard';

let lastTab = window.localStorage.getItem('lastTab') ?? 'dashboard';
if (lastTab === 'today') lastTab = 'dashboard';

const appRoute = initialTab === 'last' ? `app/${lastTab}` : `app/${initialTab}`;

const routes: Routes = [
  { path: '', redirectTo: appRoute, pathMatch: 'full' },
  {
    path: 'billing',
    loadChildren: () => import('./pages/billing/billing.module').then(m => m.BillingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'catalog',
    loadChildren: () => import('./pages/catalog/catalog.module').then(m => m.CatalogPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'configure',
    loadChildren: () => import('./pages/configure/configure.module').then(m => m.ConfigurePageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'demo',
    loadChildren: () => import('./pages/demo/demo.module').then(m => m.DemoPageModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'google',
    loadChildren: () => import('./pages/google/google.module').then(m => m.GooglePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'inactive',
    loadChildren: () => import('./pages/inactive/inactive.module').then(m => m.InactivePageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'license',
    loadChildren: () => import('./pages/license/license.module').then(m => m.LicensePageModule),
  },
  {
    path: 'pos-connect',
    loadChildren: () => import('./pages/pos-connect/pos-connect.module').then(m => m.PosConnectPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'preferences',
    loadChildren: () => import('./pages/preferences/preferences.module').then(m => m.PreferencesPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'profit',
    loadChildren: () => import('./pages/profit/profit.module').then(m => m.ProfitPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'releases',
    loadChildren: () => import('./pages/releases/releases.module').then(m => m.ReleasesPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'retrieving',
    loadChildren: () => import('./pages/retrieving/retrieving.module').then(m => m.RetrievingPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninPageModule),
  },
  {
    path: 'signout',
    loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninPageModule),
  },
  {
    path: 'unsupported',
    loadChildren: () => import('./pages/unsupported/unsupported.module').then(m => m.UnsupportedPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/update.module').then(m => m.UpdatePageModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule),
    canActivate: [ExternalGuard],
  },
  {
    path: 'app',
    loadChildren: () => import('./navigation/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard],
    data: { preload: true },
  },
  { path: 'app/**', redirectTo: 'error' },
  { path: '**', redirectTo: 'error' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader }),
  ],
  exports: [RouterModule],
  providers: [AppCustomPreloader],
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

import { User } from '@firebase/auth-types';

import { BehaviorSubject } from 'rxjs';

import { AfsService } from '@services/afs.service';
import { DtService } from '@services/dt.service';
import { EnvService } from '@services/env.service';
import { LsService } from '@services/ls.service';
import { UtilitiesService } from '@services/utilities.service';

import { UserPreferences, UserSettings } from '@shared/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserState {
  public isDemoSubject$ = new BehaviorSubject<boolean>(false);

  public settings?: UserSettings;

  public user = {
    uid: '',
    email: '',
    name: '',
  };

  public locationIDs: string[] = [];

  public masterUser = false;
  public betaUser = false;

  public admin = true;
  public readOnly = false;
  public demo = false;

  constructor(
    private afs: AfsService,
    private dt: DtService,
    private env: EnvService,
    private ls: LsService,
    private util: UtilitiesService,
  ) { }

  public resetState(): void {
    this.user.uid = '';
    this.user.email = '';
    this.user.name = '';

    this.locationIDs = [];

    this.masterUser = false;
    this.betaUser = false;

    this.admin = true;
    this.readOnly = false;
    this.demo = false;
  }

  public setUser(user: User, clientID: string): void {
    this.user.uid = user.uid;
    this.user.email = user.email ?? '';
    this.user.name = user.displayName ?? '';

    this.demo = this.isDemo(user, clientID);
    this.setReadOnly(this.readOnly);

    this.masterUser = user.uid === 'x1KUecXkmPNsmaSSdxbMOaBZBAn1' ||  // Prod
      user.uid === 'yCgoUcikWKNG5168D08T2ODWCsv1';  // Dev
  }

  private isDemo(user: User, clientID: string): boolean {
    const demoUser = clientID === 'GYRiVq9Vh';
    this.isDemoSubject$.next(demoUser);
    return demoUser;
  }

  private setReadOnly(readOnly: boolean): void {
    this.readOnly = this.demo ? true : readOnly;
  }

  public async initSettings(): Promise<void> {
    this.settings = await this.afs.getDocument<UserSettings>('users', this.user.uid, false);
    if (!this.settings) return;

    let updateNeeded = false;

    this.betaUser = this.settings.betaUser ?? false;
    this.locationIDs = this.settings.locationIDs ?? [];
    this.admin = this.settings.admin ?? this.settings.billing ?? true;

    if (!this.masterUser) {
      if (!this.settings.firstUse) this.settings.firstUse = this.dt.nowStr;
      this.settings.uses = (this.settings.uses ?? 0) + 1;
      this.settings.latestUse = this.dt.nowStr;
      this.settings.latestVersion = this.env.version;
      updateNeeded = true;
    }

    if (this.initPrefs()) {
      updateNeeded = true;
    }

    if (updateNeeded) {
      await this.afs.setDocument('users', this.user.uid, this.settings, { merge: true });
    }
  }

  private initPrefs(): boolean {
    const savedPrefs = this.util.deepClone(this.settings?.prefs);

    if (this.settings) {
      this.settings.prefs = {
        authPersistence: this.getValue('authPersistence', 'local') as string,
        comparisonDayCustomOffset: this.getValue('comparisonDayCustomOffset', 364) as number,
        comparisonDayID: this.getValue('comparisonDayID', 'prevWeek') as string,
        comparisonShowEvent: this.getValue('comparisonShowEvent', false) as boolean,
        costType: this.getValue('costType', '$') as string,
        demoPOS: this.getValue('demoPOS', 'CL') as string,
        groupByCategory: this.getValue('groupByCategory', true) as boolean,
        heatmapFilterAnimateBy: this.getValue('heatmapFilterAnimateBy', 'none') as string,
        heatmapFilterDaysOfWeek: this.getValue('heatmapFilterDaysOfWeek', '0123456') as string,
        heatmapFilterGroupBy: this.getValue('heatmapFilterGroupBy', 'channelID') as string,
        heatmapFilterLocations: this.getValue('heatmapFilterLocations', 'current') as string,
        heatmapFilterMetric: this.getValue('heatmapFilterMetric', 'sales') as string,
        heatmapFilterShowThru: this.getValue('heatmapFilterShowThru', 'yesterday') as string,
        heatmapFilterShowThruCustomDate: this.getValue('heatmapFilterShowThruCustomDate', this.dt.yesterdayStr) as string,
        heatmapFilterTimeframe: this.getValue('heatmapFilterTimeframe', 'month') as string,
        heatmapFilterTimeframeCustomTimeframe: this.getValue('heatmapFilterTimeframeCustomTimeframe', 6) as number,
        heatmapFilterTimeframeCustomUnits: this.getValue('heatmapFilterTimeframeCustomUnits', 'months') as string,
        heatmapFilterTimeOfDay: this.getValue('heatmapFilterTimeOfDay', 'typical') as string,
        heatmapFilterTimeOfDayEnd: this.getValue('heatmapFilterTimeOfDayEnd', 23) as number,
        heatmapFilterTimeOfDayStart: this.getValue('heatmapFilterTimeOfDayStart', 0) as number,
        hiddenChannels: this.getValue('hiddenChannels', '') as string,
        includeInactiveCategories: this.getValue('includeInactiveCategories', false) as boolean,
        includeInactiveItems: this.getValue('includeInactiveItems', false) as boolean,
        lastError: this.getValue('lastError', '') as string,
        locationID: this.getValue('locationID', '') as string,
        promptForGoogle: this.getValue('promptForGoogle', true) as boolean,
        showDemoMessage: this.getValue('showDemoMessage', true, true) as boolean,
        showHistoryStatus: this.getValue('showHistoryStatus', 'none') as string,
        showMigrationAlert: this.getValue('showMigrationAlert', true, true) as boolean,
        showTrendLine: this.getValue('showTrendLine', true, true) as boolean,
        stackTrends: this.getValue('stackTrends', true, true) as boolean,
        stack100: this.getValue('stack100', false, true) as boolean,
        showRefreshMessage: this.getValue('showRefreshMessage', true, true) as boolean,
        trendsFilterAggregation: this.getValue('trendsFilterAggregation', 'day') as string,
        trendsFilterAggregationMTD: this.getValue('trendsFilterAggregationMTD', 0) as number,
        trendsFilterAggregationQTD: this.getValue('trendsFilterAggregationQTD', 0) as number,
        trendsFilterAggregationYTD: this.getValue('trendsFilterAggregationYTD', 0) as number,
        trendsFilterComparison: this.getValue('trendsFilterComparison', 'none') as string,
        trendsFilterDayOfWeek: this.getValue('trendsFilterDayOfWeek', 0) as number,
        trendsFilterEvent: this.getValue('trendsFilterEvent', 'BaagG_ZwHxX') as string,
        trendsFilterGroupBy: this.getValue('trendsFilterGroupBy', 'channelID') as string,
        trendsFilterLocations: this.getValue('trendsFilterLocations', 'current') as string,
        trendsFilterMetric: this.getValue('trendsFilterMetric', 'sales') as string,
        trendsFilterQuantity: this.getValue('trendsFilterQuantity', '7days') as string,
        trendsFilterShowThru: this.getValue('trendsFilterShowThru', 'yesterday') as string,
        trendsFilterShowThruCustomDate: this.getValue('trendsFilterShowThruCustomDate', this.dt.yesterdayStr) as string,
        trendsFilterTimeOfDay: this.getValue('trendsFilterTimeOfDay', 'none') as string,
        trendsFilterTimeOfDayEnd: this.getValue('trendsFilterTimeOfDayEnd', 23) as number,
        trendsFilterTimeOfDayStart: this.getValue('trendsFilterTimeOfDayStart', 0) as number,
        zoomFactor: this.getValue('zoomFactor', 1) as number,
      };
    }

    return !this.util.isEqualDeep(savedPrefs, this.settings?.prefs);
  }

  public getPref(pref: string, value?: string | number | boolean): string | number | boolean {
    return this.getValue(pref, value ?? '');
  }

  public setPref(pref: string, value: string | number | boolean): void {
    this.ls.set(pref, String(value));

    // Don't save preferences until a user is created
    if (!this.user.uid) return;

    const userSetting = this.settings?.prefs?.[pref as keyof UserPreferences];
    if (userSetting && userSetting === value) return;

    if (this.settings?.prefs && !Object.prototype.hasOwnProperty.call(this.settings.prefs, pref)) {
      console.error('APP user.setPref: Invalid user preference: ', pref, value);
      return;
    }

    if (this.settings?.prefs && this.settings.prefs[pref as keyof UserPreferences] !== value) {
      this.settings.prefs = {
        ...this.settings.prefs,
        [pref as keyof UserPreferences]: value as typeof value,
      };
      void this.afs.setDocument('users', this.user.uid, this.settings, { merge: true });  // Update in background
    }
  }

  private getValue(pref: string, defaultValue: string | number | boolean, convertToBoolean = false):
    string | number | boolean {

    const currentValue = this.settings?.prefs?.[pref as keyof UserPreferences];
    let ls;

    if (currentValue === undefined) {
      ls = this.ls.get(pref);
      if (convertToBoolean) {
        ls = ls === '1' ? 'true' : ls === '0' ? 'false' : ls;
      }
    }

    if (typeof defaultValue === 'boolean') {
      return currentValue ?? (ls ? JSON.parse(ls) : defaultValue);
    } else if (typeof defaultValue === 'number') {
      return currentValue ?? (ls ? Number(ls) : defaultValue);
    } else {
      return currentValue ?? (ls || defaultValue);
    }
  }
}

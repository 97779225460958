import { Injectable, OnDestroy } from '@angular/core';

import {
  AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, DocumentData, DocumentReference,
  Query, QuerySnapshot,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

import { ActiveService } from '@services/active.service';
import { DtService } from '@services/dt.service';
import { ErrorService } from '@services/error.service';
import { LsService } from '@services/ls.service';
import { SignoutService } from '@services/signout.service';

import { ClientSettings } from '@shared/settings.interface';
import { TrendsByHourPacked } from '@shared/trends.interface';
import { PendingUser, PendingUserWithID, UserSettings } from '@shared/user.interface';

interface DocWithId {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AfsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private active: ActiveService,
    private afs: AngularFirestore,
    private dt: DtService,
    private error: ErrorService,
    private ls: LsService,
    private signout: SignoutService,
  ) {
    // firebase.firestore.setLogLevel('debug');
    const firestore = this.afs.firestore;
    firebase.firestore().settings({
      experimentalAutoDetectLongPolling: true,
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
      merge: true,
    });

    // Force Firestore cache to be removed based on this version's counter (currently 20)
    const clearCacheID = 21;
    if (Number(this.ls.get('clearCacheID', '0')) < clearCacheID) {
      firestore
        .clearPersistence()
        .then(() => this.ls.set('clearCacheID', String(clearCacheID)))
        .catch((error) => this.error.warn('', '', '', `Offline support not available: ${error}`));
    }

    firestore
      .enablePersistence({ synchronizeTabs: false })
      .catch((error) => this.error.warn('', '', '', `Offline support not available: ${error}`));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // *** CRUD FUNCTIONS ***

  public getSubCollection<T>(collection: string, document: string, subCollection: string): Observable<T> {
    return this.afs.collection<T>(`${collection}/${document}/${subCollection}`)
      .snapshotChanges()
      .pipe(
        map(this.convertSnapshots),
        mergeMap(data => data));
  }

  public async getDocument<T>(collection: string, document: string, throwError = true): Promise<T | undefined> {
    try {
      const docRef = this.afs.doc<T>(`${collection}/${document}`);
      if (docRef) {
        const doc = await docRef.ref.get();
        return doc.data() as T;
      } else if (throwError) {
        throw new Error(`afs.getDocument: ${collection}/${document} failed`);
      } else {
        return undefined;
      }
    } catch (error) {
      if (throwError) {
        throw new Error(`afs.getDocument: ${collection}/${document} failed with ${error}`);
      } else {
        return undefined;
      }
    }
  }

  public getValueChanges<T>(collection: string, document: string): Observable<T | undefined> {
    try {
      const doc = this.afs.doc<T>(`${collection}/${document}`);
      return doc.valueChanges();
    } catch (error) {
      throw new Error(`afs.getValueChanges: ${collection}/${document}, Error: ${error}`);
    }
  }

  public getValueChangesFromLocation<T>(collection: string, clientID: string, locationID: string):
    Observable<T[] | undefined> {

    try {
      const query = this.afs.collection<T>(collection, ref => ref
        .where('_clientID', '==', clientID)
        .where('_locationID', '==', locationID));
      return query.valueChanges();
    } catch (error) {
      throw new Error(`afs.getValueChangesFromLocation: ${collection}/${clientID}|${locationID}, Error: ${error}`);
    }
  }

  public getValueChangesFromLocations<T>(collection: string, clientID: string, locationIDs: string[]):
    Observable<T[] | undefined> {

    try {
      const query = this.afs.collection<T>(collection, ref => ref
        .where('_clientID', '==', clientID)
        .where('_locationID', 'in', locationIDs));
      // To use snapshotChanges, add pipe(map(this.convertSnapshots)) to get id
      return query.valueChanges();
    } catch (error) {
      throw new Error(`afs.getValueChangesFromLocations: ${collection}/${clientID}|${locationIDs}, Error: ${error}`);
    }
  }

  public async getToday<T>(clientID: string, locationIDs: string[], dates: string[]):
    Promise<QuerySnapshot<T> | undefined> {

    try {
      return await this.getTodayQuery(clientID, locationIDs, dates)
        .get({ source: this.active.isConnected ? 'server' : 'default' }) as QuerySnapshot<T>;
    } catch (error) {
      this.error.message('', '', 'afs.getToday', String(error));
      return;
    }
  }

  private getTodayQuery(clientID: string, locationIDs: string[], dates: string[]): Query<DocumentData> {
    // Firestore has a limit of 30 combinations of locations/dates
    const firstDate = dates.sort()[0];
    const lastDate = dates.sort()[dates.length - 1];
    if (locationIDs.length * dates.length > 30 && locationIDs.length > 30) {
      return this.afs.firestore.collection('today')
        .where('_clientID', '==', clientID)
        .where('_date', '>=', firstDate)
        .where('_date', '<=', lastDate);
    } else if (locationIDs.length * dates.length > 30) {
      return this.afs.firestore.collection('today')
        .where('_clientID', '==', clientID)
        .where('_locationID', 'in', locationIDs)
        .where('_date', '>=', firstDate)
        .where('_date', '<=', lastDate);
    } else {
      return this.afs.firestore.collection('today')
        .where('_clientID', '==', clientID)
        .where('_locationID', 'in', locationIDs)
        .where('_date', 'in', dates);
    }
  }

  public getTodayChanges<T>(clientID: string, locationIDs: string[], dates: string[]): Observable<T[] | undefined> {
    try {
      return this.getTodayChangesQuery<T>(clientID, locationIDs, dates).valueChanges();
    } catch (error) {
      throw new Error(`afs.getTodayChanges: ${clientID}|${locationIDs}, Error: ${error}`);
    }
  }

  private getTodayChangesQuery<T>(clientID: string, locationIDs: string[], dates: string[]):
    AngularFirestoreCollection<T> {

    const lastChecked = this.dt.now();

    // Firestore only allows for one inequality, so just using lastChecked (instead of dates)
    if (locationIDs.length * dates.length > 30 && locationIDs.length > 30) {
      return this.afs.collection<T>('today', ref => ref
        .where('_clientID', '==', clientID)
        .where('_lastUpdated', '>', lastChecked));
    } else if (locationIDs.length * dates.length > 30) {
      return this.afs.collection<T>('today', ref => ref
        .where('_clientID', '==', clientID)
        .where('_locationID', 'in', locationIDs)
        .where('_lastUpdated', '>', lastChecked));
    } else {
      return this.afs.collection<T>('today', ref => ref
        .where('_clientID', '==', clientID)
        .where('_locationID', 'in', locationIDs)
        .where('_date', 'in', dates)
        .where('_lastUpdated', '>', lastChecked));
    }
  }

  public getUsersChanges(clientID: string): Observable<UserSettings[] | unknown[]> {
    try {
      return this.afs.collection('users', ref => ref.where('clientID', '==', clientID)).snapshotChanges()
        .pipe(map(this.convertUsersSnapshots));
    } catch (error) {
      throw new Error(`afs.getUsersChanges: users/${clientID}, Error: ${error}`);
    }
  }

  public getPendingUsersChanges(clientID: string): Observable<PendingUserWithID[] | unknown[]> {
    try {
      return this.afs.collection('pendingUsers', ref => ref.where('_clientID', '==', clientID)).snapshotChanges()
        .pipe(map(this.convertSnapshots));
    } catch (error) {
      throw new Error(`afs.getPendingUsersChanges: users/${clientID}, Error: ${error}`);
    }
  }

  public getDocumentsWithSubcollection<T extends DocWithId>(collection: string, subCollection: string):
    Observable<T[]> {

    return this.afs.collection<T>(collection)
      .snapshotChanges()
      .pipe(
        map(this.convertSnapshots),
        map((documents: T[]) =>
          documents.map(document => {
            return this.afs.collection(`${collection}/${document.id}/${subCollection}`)
              .snapshotChanges()
              .pipe(
                map(this.convertSnapshots),
                map(subdocuments =>
                  Object.assign(document, { [subCollection]: subdocuments })
                )
              );
          })
        ),
        mergeMap(combined => combineLatest(combined))
      );
  }

  public async setDocument<T>(collection: string, document: string, data: T, options?: firebase.firestore.SetOptions):
    Promise<void> {

    const docRef = this.afs.doc<T>(`${collection}/${document}`);
    if (docRef) {
      return await docRef.ref.set(data, options ?? {});
    } else {
      if (collection === 'users') {
        this.error.message('', '', 'afs.setDocument', `Error updating users: ${document}, ${JSON.stringify(data)}`);
      }
      if (collection === 'clients') {
        this.error.message('', '', 'afs.setDocument', `Error updating clients: ${document}, ${JSON.stringify(data)}`);
      }
      throw new Error(`afs.setDocument: ${collection}/${document} failed`);
    }
  }

  public async deleteDocument<T>(collection: string, document: string, throwError = true): Promise<void> {
    const docRef = this.afs.doc<T>(`${collection}/${document}`);
    if (docRef) {
      return await docRef.ref.delete();
    } else {
      if (throwError) {
        throw new Error(`afs.deleteDocument: ${collection}/${document} failed`);
      }
    }
  }

  public deleteExtraDocs(clientID: string, locationID: string, collection: string, newCount: number): void {
    const queryResults = this.afs.collection(collection, ref => ref
      .where('_clientID', '==', clientID)
      .where('_locationID', '==', locationID)
      .where('_index', '>', newCount))
      .get();

    queryResults
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(snapshot => {
        const deletePromises = snapshot.docs.map(doc => this.afs.doc(`${collection}/${doc.id}`).delete());
        Promise.all(deletePromises).catch(error => console.error('Error deleting documents:', error));
      });
  }

  // *** OTHER FUNCTIONS ***

  public async queryClients(): Promise<QuerySnapshot<ClientSettings> | undefined> {
    try {
      const snapshot = this.afs.firestore.collection('clients');
      return await snapshot.get() as QuerySnapshot<ClientSettings>;
    } catch (error) {
      this.error.message('', '', 'afs.queryClients', String(error));
      return;
    }
  }

  public async queryTrends(clientID: string, locationID: string):
    Promise<QuerySnapshot<TrendsByHourPacked> | undefined> {

    try {
      const snapshot = this.afs.firestore.collection('trends')
        .where('_clientID', '==', clientID)
        .where('_locationID', '==', locationID);
      return await snapshot.get() as QuerySnapshot<TrendsByHourPacked>;
    } catch (error) {
      this.error.message('', '', 'afs.queryTrends', String(error));
      return;
    }
  }

  public async queryUsers(): Promise<QuerySnapshot<UserSettings> | undefined> {
    try {
      const snapshot = this.afs.firestore.collection('users');
      return await snapshot.get() as QuerySnapshot<UserSettings>;
    } catch (error) {
      this.error.message('', '', 'afs.queryUsers', String(error));
      return;
    }
  }

  public async queryPendingUser(email: string): Promise<PendingUser | undefined> {
    try {
      const pendingUsers = await this.afs.firestore.collection('pendingUsers').where('_email', '==', email).get();
      return pendingUsers?.docs.length ? pendingUsers.docs[0].data() as PendingUser : undefined;
    } catch (error) {
      this.error.message('', '', 'afs.queryUsers', String(error));
      return;
    }
  }

  public async addCheckoutSession(uid: string, priceID: string, successURL: string, failURL: string,
    trialDaysLeft: number): Promise<DocumentReference<DocumentData>> {

    return await this.afs.collection('users').doc(uid).collection('checkout_sessions').add({
      price: priceID,
      trial_from_plan: true,
      trial_end: this.dt.addDateStr(this.dt.now(), trialDaysLeft),
      billing_address_collection: 'auto',
      proration_behavior: 'always_invoice',
      success_url: successURL,
      cancel_url: failURL,
    });
  }

  private convertSnapshots<T>(snaps: DocumentChangeAction<T>[]): T[] {
    return <T[]>snaps.map(snap => {
      return {
        id: snap.payload.doc.id,
        ...snap.payload.doc.data(),
      };
    });
  }

  private convertUsersSnapshots<T>(snaps: DocumentChangeAction<T>[]): T[] {
    return <T[]>snaps.map(snap => {
      return {
        uid: snap.payload.doc.id,
        ...snap.payload.doc.data(),
      };
    });
  }
}
